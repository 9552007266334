import { useQuery } from '@tanstack/react-query';
import { queryKeysFactory } from '../utils/queryKeysFactory';
import { useSharedDependencies } from '../providers/SharedDependenciesProvider';
import { useRootStore } from '../store/root';

export const useTokenBalance = (contractAddress: string) => {
    const { op20Service } = useSharedDependencies();
    const [account, currentNetworkConfig] = useRootStore((root) => [
        root.account,
        root.currentNetworkConfig,
    ]);

    const query = useQuery({
        queryFn: () => op20Service.balanceOf(currentNetworkConfig, contractAddress, account),
        queryKey: queryKeysFactory.tokenBalance(contractAddress, account),
        enabled: !!contractAddress && !!account,
        select: (data) => data,
    });

    return query;
};
