import { Button, SvgIcon } from '@mui/material';
import React, { useState } from 'react';
import WrapBitcoinModal from '../modals/WrapUnwrapBitcoinModal';
import { WrapBitcoinIcon } from '../images/WrapBitcoinIcon';

const WrapBitcoinButton: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleWrap = (amount: number) => {
        console.log(`Wrapping ${amount} BTC to WBTC`);
        // Add your wrapping logic here
    };
    return (
        <>
            <Button
                aria-label="wrap/unwrap"
                id="wrap-button"
                aria-haspopup="true"
                onClick={handleOpenModal}
                sx={{
                    border: '1px solid white',
                    p: '7px 8px',
                    minWidth: 'unset',
                    ml: 2,
                }}>
                <SvgIcon
                    fontSize="small"
                    sx={{ ml: '8px' }}>
                    <WrapBitcoinIcon sx={{ color: 'white' }} />
                </SvgIcon>
            </Button>
            <WrapBitcoinModal
                open={isModalOpen}
                handleClose={handleCloseModal}
                handleWrap={handleWrap}
            />
        </>
    );
};

export default WrapBitcoinButton;
