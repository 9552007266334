import React, { useState } from 'react';
import {
    Box,
    Button,
    ButtonGroup,
    Divider,
    IconButton,
    Popover,
    TextField,
    Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useRootStore } from '../../store/root';
import { SlippageMode } from '../../shared/types/pool/SlippageMode';
import NumericInput from '../inputs/NumericInput';

interface TransactionSettingsPopoverProps {
    anchorEl: null | HTMLElement;
    open: boolean;
    onClose: () => void;
}

const TransactionSettingsPopover: React.FC<TransactionSettingsPopoverProps> = ({
    anchorEl,
    open,
    onClose,
}) => {
    const [
        slippageMode,
        setSlippageMode,
        slippageValue,
        setSlippageValue,
        userFeeRate,
        setUserFeeRate,
        userPriorityFee,
        setUserPriorityFee,
    ] = useRootStore((store) => [
        store.slippageMode,
        store.setSlippageMode,
        store.slippageValue,
        store.setSlippageValue,
        store.userFeeRate,
        store.setUserFeeRate,
        store.userPriorityFee,
        store.setUserPriorityFee,
    ]);
    const [expanded, setExpanded] = useState(false);

    const handleModeChange = (mode: SlippageMode) => {
        setSlippageMode(mode);
        if (mode === SlippageMode.auto) {
            setSlippageValue(10);
        }
    };

    const handleCustomSlippageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const parsedValue = parseFloat(value);
        if (!isNaN(parsedValue)) {
            setSlippageValue(parsedValue);
        }
    };

    const handlePriorityFeeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const parsedValue = parseFloat(value);
        if (!isNaN(parsedValue)) {
            setUserPriorityFee(parsedValue);
        }
    };

    const handleFeeRateRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const parsedValue = parseFloat(value);
        if (!isNaN(parsedValue)) {
            setUserFeeRate(parsedValue);
        }
    };

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            PaperProps={{
                sx: {
                    backgroundColor: '#1E1243', // Background for the entire popover
                    boxShadow: 'none',
                },
            }}>
            <Box
                p={2}
                minWidth={200}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center">
                    <Typography color="text.primary">
                        Max Slippage: {slippageMode === SlippageMode.auto ? 'Auto' : 'Custom'}
                    </Typography>
                    <IconButton
                        sx={{ color: '#FFFFFF' }}
                        onClick={toggleExpanded}>
                        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                </Box>
                {expanded && (
                    <>
                        <ButtonGroup
                            variant="contained"
                            fullWidth
                            sx={{ mt: 1, mb: 1 }}>
                            <Button
                                onClick={() => handleModeChange(SlippageMode.auto)}
                                variant={
                                    slippageMode === SlippageMode.auto ? 'contained' : 'outlined'
                                }>
                                Auto
                            </Button>
                            <Button
                                onClick={() => handleModeChange(SlippageMode.custom)}
                                variant={
                                    slippageMode === SlippageMode.custom ? 'contained' : 'outlined'
                                }>
                                Custom
                            </Button>
                        </ButtonGroup>
                        {slippageMode === SlippageMode.custom && (
                            <Box
                                mt={1}
                                display="flex"
                                alignItems="center">
                                <TextField
                                    value={slippageValue}
                                    onChange={handleCustomSlippageChange}
                                    variant="outlined"
                                    size="small"
                                    inputProps={{ style: { textAlign: 'center' } }}
                                    sx={{ width: 60, mr: 1 }}
                                />
                                <Typography>%</Typography>
                            </Box>
                        )}
                    </>
                )}
            </Box>
            <Divider
                variant="middle"
                sx={{
                    backgroundColor: 'grey',
                    my: 1,
                    height: '1px',
                    width: '80%',
                    mx: 'auto',
                }}
            />{' '}
            <Box
                minWidth={200}
                sx={{
                    pl: 2,
                }}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center">
                    <Typography color="text.primary">Fee Rate:</Typography>
                    <Box sx={{ ml: 3 }}>
                        <NumericInput
                            sx={{ fontSize: '18px', textAlign: 'right' }}
                            onChange={handleFeeRateRateChange}
                            defaultValue={userFeeRate}
                        />
                    </Box>
                </Box>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center">
                    <Typography color="text.primary">Priority Fee</Typography>
                    <Box sx={{ ml: 3 }}>
                        <NumericInput
                            sx={{ fontSize: '18px', textAlign: 'right' }}
                            onChange={handlePriorityFeeChange}
                            defaultValue={userPriorityFee}
                        />
                    </Box>
                </Box>
            </Box>
        </Popover>
    );
};

export default TransactionSettingsPopover;
