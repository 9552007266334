import { Button, Link, List, ListItem, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { navigation } from '../../configs/ui-config/navigation';

export const NavItems = () => {
    const { breakpoints } = useTheme();
    const md = useMediaQuery(breakpoints.down('md'));
    return (
        <List
            sx={{
                display: 'flex',
                alignItems: { xs: 'flex-start', md: 'center' },
                flexDirection: { xs: 'column', md: 'row' },
            }}
            disablePadding>
            {navigation
                .filter((item) => !item.isVisible)
                .map((item, index) => (
                    <ListItem
                        sx={{
                            width: { xs: '100%', md: 'unset' },
                            mr: { xs: 0, md: 2 },
                        }}
                        disablePadding
                        key={index}>
                        {md ? (
                            <Typography
                                component={Link}
                                href={item.link}
                                variant="h2"
                                underline="none"
                                sx={{
                                    width: '100%',
                                    p: 4,
                                    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                                }}>
                                {item.title}
                            </Typography>
                        ) : (
                            <Button
                                component={Link}
                                href={item.link}
                                sx={(theme) => ({
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    color: '#E7E7E9',
                                    p: '6px 8px',
                                    position: 'relative',
                                    '.active&:after, &:hover&:after': {
                                        transform: 'scaleX(1)',
                                        transformOrigin: 'bottom left',
                                    },
                                    '&:after': {
                                        content: "''",
                                        fontStyle: 'italic',
                                        position: 'absolute',
                                        width: '100%',
                                        transform: 'scaleX(0)',
                                        height: '2px',
                                        bottom: '-6px',
                                        left: '0',
                                        background: theme.palette.background.surface,
                                        transformOrigin: 'bottom right',
                                        transition: 'transform 0.25s ease-out',
                                    },
                                })}>
                                {item.title}
                            </Button>
                        )}
                    </ListItem>
                ))}
        </List>
    );
};
