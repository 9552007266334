import { Address } from '@btc-vision/bsi-binary';
import {
    CallResult,
    getContract,
    IMotoswapPoolContract,
    JSONRpcProvider,
    MotoswapPoolAbi,
} from 'opnet';
import { NetworkKey } from '../configs/ui-config/NetworkKey';
import { NetworkConfig } from '../shared/types/NetworkConfig';
import { PoolReserve } from '../shared/types/pool/PoolReserve';
import { sortTokens } from '../utils/contractUtils';
import { getNetworkConfigKey } from '../utils/marketAndNetworkUtils';

export class PoolService {
    constructor(private readonly getProvider: (network: NetworkKey) => JSONRpcProvider) {}

    public async GetReserveDataHumanized(
        currentNetworkConfig: NetworkConfig,
        contractAddress: Address,
        token0Address: Address,
        token1Address: Address,
    ): Promise<PoolReserve> {
        try {
            const contractResult = await this.getReserves(currentNetworkConfig, contractAddress);
            const sorted = sortTokens(token0Address, token1Address);

            const token0 = await this.getToken0(currentNetworkConfig, contractAddress);

            let reverse = false;

            if (token0 !== token1Address) reverse = true;

            const parsedResult = (
                contractResult as CallResult<{
                    blockTimestampLast: bigint;
                    reserve0: bigint;
                    reserve1: bigint;
                }>
            ).properties;

            const reserve: PoolReserve = {
                address: contractAddress,
                blockTimestampLast: parsedResult.blockTimestampLast,
                reserve0TokenAddress: reverse ? sorted[1] : sorted[0],
                reserve0: reverse ? parsedResult.reserve1 : parsedResult.reserve0,
                reserve1TokenAdress: reverse ? sorted[0] : sorted[1],
                reserve1: reverse ? parsedResult.reserve0 : parsedResult.reserve1,
            };

            return reserve;
        } catch (err) {
            throw err;
        }
    }

    public async getReserves(currentNetworkConfig: NetworkConfig, contractAddress: string) {
        try {
            const contract = getContract<IMotoswapPoolContract>(
                contractAddress,
                MotoswapPoolAbi,
                this.getProvider(getNetworkConfigKey(currentNetworkConfig)),
                currentNetworkConfig.addresses.ROUTER,
            );

            const contractResult = await contract.getReserves();
            return contractResult;
        } catch (err) {
            throw err;
        }
    }

    public async getToken0(
        currentNetworkConfig: NetworkConfig,
        contractAddress: string,
    ): Promise<string> {
        try {
            const contract = getContract<IMotoswapPoolContract>(
                contractAddress,
                MotoswapPoolAbi,
                this.getProvider(getNetworkConfigKey(currentNetworkConfig)),
            );
            const contractResult = await contract.token0();
            return (contractResult as CallResult).decoded[0].toString();
        } catch (err) {
            throw err;
        }
    }

    public async getToken1(
        currentNetworkConfig: NetworkConfig,
        contractAddress: string,
    ): Promise<string> {
        try {
            const contract = getContract<IMotoswapPoolContract>(
                contractAddress,
                MotoswapPoolAbi,
                this.getProvider(getNetworkConfigKey(currentNetworkConfig)),
            );
            const contractResult = await contract.token1();
            return (contractResult as CallResult).decoded[0].toString();
        } catch (err) {
            throw err;
        }
    }
}
