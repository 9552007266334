import { useQuery } from '@tanstack/react-query';
import { queryKeysFactory } from '../utils/queryKeysFactory';
import { useSharedDependencies } from '../providers/SharedDependenciesProvider';
import { useRootStore } from '../store/root';

export const useTokenMetdata = (contractAddress: string) => {
    const { op20Service } = useSharedDependencies();
    const currentNetworkConfig = useRootStore((store) => store.currentNetworkConfig);

    const query = useQuery({
        queryFn: () => op20Service.GetTokenMetadata(currentNetworkConfig, contractAddress),
        queryKey: queryKeysFactory.tokenMetdata(contractAddress),
        enabled: !!contractAddress,
        select: (data) => data,
    });

    return query;
};
