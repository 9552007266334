import React, { useState } from 'react';
import { Box, Button, IconButton, Link, Paper, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Token } from '../../shared/types/Token';
import { useSnackbarContext } from '../../providers/SnackProvider';
import { ArrowBack } from '@mui/icons-material';
import TokenSelectInput from '../../components/inputs/TokenSelectInput';
import { useReservesData } from '../../hooks/useReservesData';
import { useRootStore } from '../../store/root';
import { ROUTES } from '../../configs/ui-config/Routes';

const ImportLiquidityPanel: React.FC = () => {
    const { showSnackbar } = useSnackbarContext();
    const [addUserPool] = useRootStore((store) => [store.addUserPool]);

    const [tokenA, setTokenA] = useState<Token | undefined>(undefined);
    const handleTokenA = (token: Token) => {
        setTokenA(token);
    };
    const [tokenB, setTokenB] = useState<Token | undefined>(undefined);
    const handleTokenB = (token: Token) => {
        setTokenB(token);
    };

    const { poolAddress, error: reservesError } = useReservesData({
        tokenIn: tokenA,
        tokenOut: tokenB,
    });

    const handleImportLiquidity = async () => {
        try {
            if (poolAddress) {
                addUserPool(poolAddress);
                showSnackbar('Success!');
            }
        } catch (error) {
            showSnackbar('Error adding liquidity.');
        }
    };

    return (
        <Paper
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                p: { xs: 2, sm: 4 },
                boxSizing: 'border-box',
                position: 'relative',
                width: 400,
            }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: 16,
                    left: 16,
                }}>
                <Link
                    component={RouterLink}
                    to={ROUTES.pool}>
                    <IconButton>
                        <ArrowBack sx={{ color: 'white' }} />
                    </IconButton>
                </Link>
            </Box>
            <Typography
                variant="h2"
                sx={{ mb: 2 }}>
                Import Pool
            </Typography>
            <Box
                sx={{
                    backgroundColor: '#1E1243',
                    p: 2,
                    borderRadius: 1,
                    width: '100%',
                    mb: 2,
                    textAlign: 'left',
                }}>
                <Typography
                    variant="main12"
                    sx={{ color: 'white' }}>
                    Tip:Use this tool to find v2 pools that don’t automatically appear in the
                    interface.{' '}
                </Typography>
            </Box>
            <TokenSelectInput setUserSelectedToken={handleTokenA} />
            <Typography
                variant="h4"
                sx={{ mb: 2 }}>
                +
            </Typography>
            <TokenSelectInput setUserSelectedToken={handleTokenB} />
            {reservesError && reservesError}
            <Button
                disabled={!poolAddress || !!reservesError}
                variant="contained"
                color="primary"
                fullWidth
                sx={{ backgroundColor: '#6a1b9a' }}
                onClick={() => handleImportLiquidity()}>
                Import Pool
            </Button>
        </Paper>
    );
};

export default ImportLiquidityPanel;
