import { useQuery } from '@tanstack/react-query';
import { queryKeysFactory } from '../utils/queryKeysFactory';
import { useRootStore } from '../store/root';
import { Token } from '../shared/types/Token';
import { NetworkConfig } from '../shared/types/NetworkConfig';

const fetchTokens = (currentNetworkConfig: NetworkConfig): Token[] => {
    const topTokens: Token[] = [
        {
            symbol: 'WBTC',
            name: 'Wrapped Bitcoin',
            contractAddress: currentNetworkConfig.addresses.WBTC,
            decimals: 8,
        },
        {
            symbol: 'MOTO',
            name: 'Moto Token',
            contractAddress: currentNetworkConfig.addresses.MOTO,
            decimals: 8,
        },
    ];

    return topTokens;
};
export const useTopTokens = () => {
    const currentNetworkConfig = useRootStore((state) => state.currentNetworkConfig);

    return useQuery<Token[], Error>({
        queryFn: () => fetchTokens(currentNetworkConfig),
        queryKey: queryKeysFactory.topTokens(currentNetworkConfig),
        enabled: true,
        initialData: [],
    });
};
