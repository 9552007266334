export function calculateTransactionFee(
    txSize: number,
    feeRate: bigint,
    priorityFee: bigint,
): bigint {
    return BigInt(txSize) * feeRate + priorityFee;
}

export function calculateTotalInputs(utxos: { value: bigint }[]): bigint {
    return utxos.reduce((total, utxo) => total + utxo.value, 0n);
}

export function estimateTransactionSize(numInputs: number, numOutputs: number, callDataLength = 0) {
    return numInputs * 148 + numOutputs * 34 + 10 + callDataLength;
}
