import * as React from 'react';
import { Alert, Box, Snackbar } from '@mui/material';

export interface BasicSnackBarProps {
    open: boolean;
    message: string;
    onClose: () => void;
    severity?: 'success' | 'error' | 'warning' | 'info';
    autoHideDuration?: number;
    children?: React.ReactNode;
}

const style = {
    backgroundColor: '#1E1243',
    color: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    padding: '2',
    borderRadius: '8px',
    boxShadow: '6',
};

export const BasicSnackBar: React.FC<BasicSnackBarProps> = ({
    open,
    message,
    onClose,
    autoHideDuration = 3000,
    severity = 'info',
    children,
    ...props
}) => {
    return (
        <Snackbar
            open={open}
            autoHideDuration={autoHideDuration}
            onClose={onClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
            <Alert
                onClose={onClose}
                severity={severity}
                sx={{ width: '100%', ...style }}
                {...props}>
                <Box>{message}</Box>
                {children && <Box sx={{ ml: 1 }}>{children}</Box>}
            </Alert>
        </Snackbar>
    );
};
