import { StateCreator } from 'zustand';
import { RootStore } from './root';

export interface SwapSlice {
    slippage: number;
    setSlippage: (slippage: number) => void;
    tokenInAddress: string;
    setTokenInAddress: (tokenInAddress: string) => void;
    tokenOutAddress: string;
    setTokenOutAddress: (tokenOutAddress: string) => void;
    liquidityPoolAddress: string;
    setLiquidityPoolAddress: (liquidityPoolAddress: string) => void;
}

export const createSwapSlice: StateCreator<
    RootStore,
    [['zustand/subscribeWithSelector', never], ['zustand/devtools', never]],
    [],
    SwapSlice
> = (set) => {
    return {
        slippage: 0.5,
        setSlippage(slippage) {
            set({ slippage: slippage });
        },
        tokenInAddress: '',
        setTokenInAddress(tokenInAddress) {
            set({ tokenInAddress: tokenInAddress });
        },
        tokenOutAddress: '',
        setTokenOutAddress(tokenOutAddress) {
            set({ tokenOutAddress: tokenOutAddress });
        },
        liquidityPoolAddress: '',
        setLiquidityPoolAddress(liquidityPoolAddress) {
            set({ liquidityPoolAddress: liquidityPoolAddress });
        },
    };
};
