import { Network } from 'bitcoinjs-lib';
import { produce } from 'immer';
import { StateCreator } from 'zustand';
import { Op20Service } from '../services/Op20Service';
import { UiPoolService } from '../services/UIPoolService';
import { NetworkConfig } from '../shared/types/NetworkConfig';
import { SlippageMode } from '../shared/types/pool/SlippageMode';
import { UserPool } from '../shared/types/pool/UserPool';
import { ApproveType } from '../shared/types/transactions/Approval';
import { PopulatedTransaction } from '../shared/types/transactions/PopuatedTransaction';
import { getProvider } from '../utils/marketAndNetworkUtils';
import { MAX_UINT256 } from '../utils/tokenUtils';
import { RootStore } from './root';

type GenerateApprovalOpts = {
    network?: Network;
};

export interface PoolSlice {
    slippageMode: SlippageMode;
    slippageValue: number;
    userPriorityFee: number;
    userFeeRate: number;
    userPools: Map<string, string[]>;
    userPoolData: Map<string, Map<string, UserPool>>;
    setUserPriorityFee: (fee: number) => void;
    setUserFeeRate: (fee: number) => void;
    generateApproval: (args: ApproveType, opts?: GenerateApprovalOpts) => PopulatedTransaction;
    setSlippageMode: (mode: SlippageMode) => void;
    setSlippageValue: (value: number) => void;
    addUserPool: (address: string) => void;
    setUserPoolData: (network: Network, poolAddress: string, userPoolData: UserPool) => void;
    refreshUserPoolData: (networkConfig?: NetworkConfig) => Promise<void>;
}

const getLocalUserPools = (): Map<string, string[]> => {
    const data = localStorage.getItem('motoswap_user_pools');
    if (data) {
        return new Map(Object.entries(JSON.parse(data)));
    } else {
        return new Map<string, string[]>();
    }
};

export const createPoolSlice: StateCreator<
    RootStore,
    [['zustand/subscribeWithSelector', never], ['zustand/devtools', never]],
    [],
    PoolSlice
> = (set, get) => {
    const initialSlippageMode = SlippageMode.auto;
    const initialSlippageValue = 10;
    const initialPriorityFee = 30000;
    const initialFeeRate = 450;
    const initialPools = getLocalUserPools();
    return {
        slippageMode: initialSlippageMode,
        slippageValue: initialSlippageValue,
        userPriorityFee: initialPriorityFee,
        userFeeRate: initialFeeRate,
        userPools: initialPools,
        userPoolData: new Map(),
        setUserPriorityFee: (fee) => {
            set({ userPriorityFee: fee });
        },
        setUserFeeRate: (fee) => {
            set({ userFeeRate: fee });
        },
        generateApproval: (args: ApproveType, ops = {}) => {
            const networkConfig = get().currentNetworkConfig;
            const op20Service = new Op20Service(getProvider);
            const tx = op20Service.approveTxData(networkConfig, {
                ...args,
                amount: MAX_UINT256,
            });
            return tx;
        },
        setSlippageMode(mode) {
            set({ slippageMode: mode });
        },
        setSlippageValue(value) {
            set({ slippageValue: value });
        },
        addUserPool: (address) => {
            const currentNetwork = get().currentNetworkConfig.name;
            set((state) => {
                const updatedPools = produce(state.userPools, (draft) => {
                    if (!draft.has(currentNetwork)) {
                        draft.set(currentNetwork, []);
                    }
                    const networkPools = draft.get(currentNetwork);
                    if (networkPools) {
                        networkPools.push(address);
                    }
                });
                localStorage.setItem(
                    'motoswap_user_pools',
                    JSON.stringify(Object.fromEntries(updatedPools)),
                );
                return { userPools: updatedPools };
            });
        },
        setUserPoolData: (network, poolAddress, userPoolData) =>
            set(
                produce((state) => {
                    if (!state.userPoolData.has(network)) {
                        state.userPoolData.set(network, new Map());
                    }
                    state.userPoolData.get(network).set(poolAddress, userPoolData);
                }),
            ),
        refreshUserPoolData: async (networkConfig?: NetworkConfig) => {
            const { account, currentNetworkConfig, userPools } = get();
            const selectedNetworkConfig = networkConfig || currentNetworkConfig;
            const currentNetwork = selectedNetworkConfig.name;

            if (!account) return;

            const userPoolDataProvider = new UiPoolService(getProvider);
            const pools = new Set(userPools.get(currentNetwork)) ?? new Set<string>();
            const errors: string[] = [];

            try {
                for (const pool of pools) {
                    try {
                        const userPoolData = await userPoolDataProvider.getUserPoolDataHumanized(
                            selectedNetworkConfig,
                            pool,
                            account,
                        );
                        set((state) =>
                            produce(state, (draft) => {
                                if (!draft.userPoolData.has(currentNetwork)) {
                                    draft.userPoolData.set(currentNetwork, new Map());
                                }
                                const networkData = draft.userPoolData.get(currentNetwork);
                                if (networkData) {
                                    networkData.set(pool, userPoolData);
                                }
                            }),
                        );
                    } catch (error) {
                        errors.push(`Error fetching data for pool ${pool}: ${error}`);
                    }
                }
                if (errors.length > 0) {
                    console.log(
                        'Errors occurred while fetching user pool data:',
                        errors.join(', '),
                    );
                }
            } catch (e) {
                console.log('Error fetching user pool data', e);
            }
        },
    };
};
