import React, { createContext, useContext, useState } from 'react';
import { TxErrorType } from '../../src/configs/ui-config/errorMappings';
import { Token } from '../shared/types/Token';

export enum ModalType {
    SwapSettings,
    AddLiquidity,
    Swap,
}

export interface ModalArgsType {
    token0?: Token;
    token0Amount?: string;
    token1?: Token;
    token1Amount?: string;
    priceImpact?: number;
}

export type TxStateType = {
    txHash?: string;
    txError?: string;
    loading?: boolean;
    success?: boolean;
};

export interface ModalContextType<T extends ModalArgsType> {
    openSwap: (
        token0: Token,
        token0Amount: string,
        token1: Token,
        token1Amount: string,
        priceImpact: number,
    ) => void;
    openAddLiquidity: (
        token0: Token,
        token0Amount: string,
        token1: Token,
        token1Amount: string,
    ) => void;
    openSwapSettings: () => void;
    close: () => void;
    type?: ModalType;
    args: T;
    mainTxState: TxStateType;
    approvalTxState: TxStateType;
    setApprovalTxState: (data: TxStateType) => void;
    setMainTxState: (data: TxStateType) => void;
    gasLimit: string;
    setGasLimit: (limit: string) => void;
    loadingTxns: boolean;
    setLoadingTxns: (loading: boolean) => void;
    txError: TxErrorType | undefined;
    setTxError: (error: TxErrorType | undefined) => void;
}

export const ModalContext = createContext<ModalContextType<ModalArgsType>>(
    {} as ModalContextType<ModalArgsType>,
);

export interface ModelContextProviderProps {
    children: React.ReactNode;
}

export const ModalContextProvider: React.FC<ModelContextProviderProps> = ({ children }) => {
    // contains the current modal open state if any
    const [type, setType] = useState<ModalType>();
    // contains arbitrary key-value pairs as a modal context
    const [args, setArgs] = useState<ModalArgsType>({});
    const [txError, setTxError] = useState<TxErrorType>();
    const [gasLimit, setGasLimit] = useState<string>('');
    const [mainTxState, setMainTxState] = useState<TxStateType>({});
    const [approvalTxState, setApprovalTxState] = useState<TxStateType>({});
    const [loadingTxns, setLoadingTxns] = useState(false);

    return (
        <ModalContext.Provider
            value={{
                openSwap: (token0, token0Amount, token1, token1Amount, priceImpact) => {
                    setType(ModalType.Swap);
                    setArgs({ token0, token0Amount, token1, token1Amount, priceImpact });
                },
                openAddLiquidity: (token0, token0Amount, token1, token1Amount) => {
                    setType(ModalType.AddLiquidity);
                    setArgs({ token0, token0Amount, token1, token1Amount });
                },
                openSwapSettings: () => {
                    setType(ModalType.SwapSettings);
                },
                close: () => {
                    setType(undefined);
                    setArgs({});
                    setMainTxState({});
                    setApprovalTxState({});
                    setGasLimit('');
                    setTxError(undefined);
                },
                type,
                args,
                approvalTxState,
                mainTxState,
                setApprovalTxState,
                setMainTxState,
                gasLimit,
                setGasLimit,
                loadingTxns,
                setLoadingTxns,
                txError,
                setTxError,
            }}>
            {children}
        </ModalContext.Provider>
    );
};

export const useModalContext = () => {
    const context = useContext(ModalContext);

    if (context === undefined) {
        throw new Error('useModalContext must be used within a ModalProvider');
    }

    return context;
};
