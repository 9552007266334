import {
    BroadcastTransactionOptions,
    InteractionParametersWithoutSigner,
    IWrapParameters,
    OPNetLimitedProvider,
    TransactionFactory,
    UnisatChainType,
    UnisatSigner,
    WrappedGeneration,
} from '@btc-vision/transaction';
import { BroadcastedTransaction, JSONRpcProvider, UTXO } from 'opnet';
import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { NetworkKey } from '../configs/ui-config/NetworkKey';
import { Web3Context } from '../hooks/useWeb3Context';
import { PopulatedTransaction } from '../shared/types/transactions/PopuatedTransaction';
import { useRootStore } from '../store/root';
import { getNetworkConfigKey, getProvider } from '../utils/marketAndNetworkUtils';
import {
    calculateTotalInputs,
    calculateTransactionFee,
    estimateTransactionSize,
} from '../utils/networkUtils';
import { hexToAscii } from '../utils/utils';

interface Web3ContextProviderProps {
    children: ReactElement;
    provider?: JSONRpcProvider;
}

interface ExtendedUnisatChainType {
    enum: string;
    name: string;
    network: string;
}

function mapUnisatNetwork(network: ExtendedUnisatChainType): NetworkKey {
    switch (network.enum) {
        case UnisatChainType.BITCOIN_MAINNET:
            return NetworkKey.Bitcoin;
        case UnisatChainType.BITCOIN_REGTEST:
            return NetworkKey.Regtest;
        case UnisatChainType.BITCOIN_TESTNET:
            return NetworkKey.Testnet;
        case UnisatChainType.FRACTAL_BITCOIN_TESTNET:
            return NetworkKey.FractalTestnet;
        default:
            throw new Error(`Unknown network: ${network.name}`);
    }
}

export const Web3ContextProvider: React.FC<Web3ContextProviderProps> = (
    props: Web3ContextProviderProps,
) => {
    const [
        currentNetworkConfig,
        userPriorityFee,
        userFeeRate,
        walletUtxos,
        setWalletUtxos,
        setSigner,
        setBitcoinBalance,
    ] = useRootStore((store) => [
        store.currentNetworkConfig,
        store.userPriorityFee,
        store.userFeeRate,
        store.walletUtxos,
        store.setWalletUtxos,
        store.setSigner,
        store.setBitcoinBalance,
    ]);
    const { children } = props;

    //this will be moved to store
    const transactionFactory: TransactionFactory = new TransactionFactory();
    const [unisatInstalled, setUnisatInstalled] = useState(false);
    const [connected, setConnected] = useState(false);
    const [address, setAddress] = useState('');
    const [network, setNetwork] = useState<NetworkKey | undefined>();

    const provider = getProvider(getNetworkConfigKey(currentNetworkConfig));
    const unisatSigner = useMemo(() => new UnisatSigner(), []);
    const utxoManager = new OPNetLimitedProvider(
        currentNetworkConfig.privateJsonRPCUrl ? currentNetworkConfig.privateJsonRPCUrl : '',
    );

    //store
    const [setAccount] = useRootStore((store) => [store.setAccount]);

    const getBasicInfo = useCallback(async () => {
        const unisat = window.opnet || window.unisat;
        if (!unisat) return;

        const [address] = await unisat.getAccounts();
        setAddress(address);

        const balance = await unisat.getBalance();
        setSigner(unisatSigner);
        setBitcoinBalance(balance);
    }, [setSigner, setBitcoinBalance, unisatSigner]);

    const selfRef = useRef<{ accounts: string[] }>({
        accounts: [],
    });

    const self = selfRef.current;

    const handleAccountsChanged = useCallback(
        async (_accounts: string[]) => {
            self.accounts = _accounts;
            if (_accounts.length > 0) {
                setConnected(true);

                setAccount(_accounts[0]);

                await getBasicInfo();
            } else {
                setConnected(false);
            }
        },
        [getBasicInfo, setConnected, setAccount, self],
    );

    const handleNetworkChanged = useCallback(
        async (uNetwork: ExtendedUnisatChainType) => {
            const mappedNetwork = mapUnisatNetwork(uNetwork);
            setNetwork(mappedNetwork);
            await getBasicInfo();
        },
        [getBasicInfo],
    );

    const getTxError = async (txHash: string): Promise<string> => {
        if (provider) {
            const tx = await provider.getTransaction(txHash);
            // @ts-expect-error TODO: need think about "tx" type
            const code = (await provider.call(tx, tx.blockNumber)) as CallResult;
            const error = hexToAscii(code.substr(138));
            return error;
        }
        throw new Error('Error getting transaction. Provider not found');
    };

    useEffect(() => {
        async function checkUnisat() {
            let unisat = window.opnet || window.unisat;

            for (let i = 1; i < 10 && !unisat; i += 1) {
                await new Promise((resolve) => setTimeout(resolve, 100 * i));
                unisat = window.opnet || window.unisat;
            }

            if (unisat) {
                setUnisatInstalled(true);
            } else if (!unisat) return;

            unisat.getAccounts().then((accounts: string[]) => {
                handleAccountsChanged(accounts);
            });

            unisat.getChain().then((uniNetwork) => {
                const networkInfo = uniNetwork as unknown as ExtendedUnisatChainType;
                handleNetworkChanged(networkInfo);
            });

            unisat.on('accountsChanged', handleAccountsChanged);
            // unisat.on('chainChanged', handleNetworkChanged);

            return () => {
                if (!unisat) throw new Error('Unisat not found');

                unisat.removeListener('accountsChanged', handleAccountsChanged);
                // unisat.removeListener('networkChanged', handleNetworkChanged);
            };
        }

        checkUnisat().then();
    }, [handleAccountsChanged, handleNetworkChanged]);

    const disconnectWallet = () => {
        setAddress('');
        setConnected(false);
    };

    const connectWallet = async () => {
        if (unisatInstalled) {
            if (window.opnet) {
                try {
                    const accounts = await window.opnet.requestAccounts();
                    await handleAccountsChanged(accounts);
                } catch (error) {
                    console.error('Error connecting wallet:', error);
                }
            } else if (window.unisat) {
                try {
                    const accounts = await window.unisat.requestAccounts();
                    await handleAccountsChanged(accounts);
                } catch (error) {
                    console.error('Error connecting wallet:', error);
                }
            } else {
                throw new Error('OP_WALLET or UniSat not found');
            }
        } else {
            console.error('UniSat Wallet not installed');
        }
    };

    const fetchUtxos = async (): Promise<UTXO[]> => {
        try {
            await unisatSigner.init();

            const utxoSetting = {
                addresses: unisatSigner.addresses,
                minAmount: 50_000n,
                requestedAmount: 100_000n,
            };

            const uxtoData = await utxoManager.fetchUTXOMultiAddr(utxoSetting);

            return uxtoData;
        } catch (error) {
            throw error;
        }
    };

    const fetchWrapParameters = async (amount: bigint): Promise<WrappedGeneration | undefined> => {
        return await utxoManager.fetchWrapParameters(amount);
    };

    // TX
    const signMessage = async (
        tx: PopulatedTransaction,
        utxos?: UTXO[],
    ): Promise<[string, string, UTXO[]]> => {
        if (!window.opnet || !window.unisat) throw new Error('OP_WALLET or UniSat not found');

        try {
            await unisatSigner.init();

            const utxoSetting = {
                addresses: unisatSigner.addresses, //wallet.p2wpkh, wallet.p2tr
                minAmount: 241750n,
                requestedAmount: 241750n,
            };

            utxos = await utxoManager.fetchUTXOMultiAddr(utxoSetting);
            const totalInputs = calculateTotalInputs(utxos);

            if (!tx.callData || tx.callData.length === 0) {
                throw new Error('No call data provided');
            }

            const txSize = estimateTransactionSize(utxos.length, 2, tx.callData.length); // 1 output for the contract call + 1 change
            const txFee = calculateTransactionFee(
                txSize,
                BigInt(userFeeRate),
                BigInt(userPriorityFee),
            );
            const totalNeeded = txFee;
            const changeAmount = totalInputs - totalNeeded;

            const outputs = [];

            if (changeAmount > 0) {
                outputs.push({ address: unisatSigner.p2tr, amount: changeAmount });
            }

            const interactionData = {
                from: unisatSigner.p2tr,
                to: tx.toAddress,
                utxos: utxos,
                network: unisatSigner.network,
                estimatedFees: txFee,
                feeRate: userFeeRate,
                priorityFee: BigInt(userPriorityFee),
                calldata: tx.callData,
            };

            if (window.opnet.web3) {
                return await window.opnet.web3.signInteraction(interactionData);
            } else if (window.unisat.web3) {
                return await window.unisat.web3.signInteraction(interactionData);
            } else {
                throw new Error('Web3 provider not found');
            }
        } catch (error) {
            console.error('Error in Web3ContextProvider:signMessage', error);
            throw error;
        }
    };

    const signAndBroadcastTransaction = async (
        tx: PopulatedTransaction,
    ): Promise<[BroadcastedTransaction, BroadcastedTransaction, UTXO[]]> => {
        if (!window.opnet || !window.unisat) {
            throw new Error('OP_WALLET or UniSat not found');
        }

        try {
            await unisatSigner.init();

            let utxos = walletUtxos;

            if (!utxos || !utxos[0] || utxos.length <= 0) {
                const utxoSetting = {
                    addresses: unisatSigner.addresses, //wallet.p2wpkh, wallet.p2tr
                    minAmount: 50_000n,
                    requestedAmount: 100_000n,
                    optimized: true,
                };

                utxos = await utxoManager.fetchUTXOMultiAddr(utxoSetting);
            }

            const interactionData = {
                from: unisatSigner.p2tr,
                to: tx.toAddress,
                utxos: utxos,
                network: unisatSigner.network,
                feeRate: userFeeRate,
                priorityFee: BigInt(userPriorityFee),
                calldata: tx.callData,
            } as InteractionParametersWithoutSigner;

            if (window.opnet.web3) {
                const transaction =
                    await window.opnet.web3.signAndBroadcastInteraction(interactionData);
                setWalletUtxos(transaction[2]);
                return transaction;
            } else if (window.unisat.web3) {
                const transaction =
                    await window.unisat.web3.signAndBroadcastInteraction(interactionData);
                setWalletUtxos(transaction[2]);
                return transaction;
            } else {
                throw new Error('Web3 provider not found');
            }
        } catch (error) {
            console.error('Error in Web3ContextProvider:signAndBroadcastMessage', error);
            throw error;
        }
    };

    const sendRawTransaction = async (
        transaction: BroadcastTransactionOptions,
    ): Promise<BroadcastedTransaction> => {
        if (window.opnet && window.opnet.web3) {
            const txResponse = await window.opnet.web3?.broadcast([transaction]);
            return txResponse[0];
        } else if (window.unisat && window.unisat.web3) {
            const txResponse = await window.unisat.web3?.broadcast([transaction]);
            return txResponse[0];
        }
        throw new Error('Web3 provider not found');
    };

    //todo: utxo handling hardening
    const wrap = async (wrapParameters: IWrapParameters) => {
        try {
            const txToSend = await transactionFactory.wrap(wrapParameters);
            localStorage.setItem('utxoData', JSON.stringify(txToSend.utxos));
        } catch (error) {
            throw error;
        }
    };

    return (
        <Web3Context.Provider
            value={{
                web3ProviderData: {
                    connectWallet,
                    disconnectWallet,
                    currentNetwork: network,
                    currentAccount: address?.toLowerCase() || '',
                    connected,
                    signMessage,
                    signAndBroadcastTransaction,
                    sendRawTransaction,
                    signPsbt: function (psbt: string): Promise<string> {
                        throw new Error(`Function not implemented to sign ${psbt}`);
                    },
                    provider,
                    getTxError,
                    fetchUtxos,
                    fetchWrapParameters,
                    wrap,
                },
            }}>
            {children}
        </Web3Context.Provider>
    );
};
