import { Box, Link, SvgIcon, Typography, styled } from '@mui/material';
import { Twitter, Telegram, GitHub } from '@mui/icons-material';
import React from 'react';
import { ReactComponent as DiscordIcon } from '../components/images/DiscordIcon.svg';

interface StyledLinkProps {
    onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

const StyledLink = styled(Link)<StyledLinkProps>(({ theme }) => ({
    color: theme.palette.text.primary,
    '&:hover': {
        color: theme.palette.text.primary,
    },
    display: 'flex',
    alignItems: 'center',
}));

const FOOTER_ICONS = [
    {
        href: 'https://x.com/MotoswapBTC',
        icon: <Twitter />,
        title: 'Twitter',
    },
    {
        href: 'https://t.me/Motoswap',
        icon: <Telegram />,
        title: 'Telegram',
    },
    {
        href: 'https://discord.gg/Motoswap',
        icon: <DiscordIcon />,
        title: 'Discord',
    },
    {
        href: 'https://github.com/btc-vision',
        icon: <GitHub />,
        title: 'GitHub',
    },
];

export function AppFooter() {
    const FOOTER_LINKS = [
        {
            href: 'https://opnet.org',
            label: 'OP_NET',
            key: 'OP_NET',
        },
        {
            href: 'https://opscan.org',
            label: 'OP_SCAN',
            key: 'OP_SCAN',
        },
    ];

    return (
        <Box
            sx={(theme) => ({
                display: 'flex',
                padding: ['22px 0px 40px 0px', '0 22px 0 40px', '20px 22px'],
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '22px',
                flexDirection: ['column', 'column', 'row'],
                boxShadow:
                    theme.palette.mode === 'light'
                        ? 'inset 0px 1px 0px rgba(0, 0, 0, 0.04)'
                        : 'inset 0px 1px 0px rgba(255, 255, 255, 0.12)',
            })}>
            <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                {FOOTER_LINKS.map((link) => (
                    <StyledLink
                        key={link.key}
                        href={link.href}
                        target="_blank"
                        underline="none"
                        rel="noreferrer">
                        <Typography variant="caption">{link.label}</Typography>
                    </StyledLink>
                ))}
            </Box>
            <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                {FOOTER_ICONS.map((icon) => (
                    <StyledLink
                        href={icon.href}
                        key={icon.title}
                        target="_blank"
                        underline="none"
                        rel="noreferrer">
                        <SvgIcon
                            sx={{
                                color: 'white',
                                fontSize: [24, 24, 20],
                            }}>
                            {icon.icon}
                        </SvgIcon>
                    </StyledLink>
                ))}
            </Box>
        </Box>
    );
}
