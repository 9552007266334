import { Box, Button, Divider, SvgIcon } from '@mui/material';
import React, { ReactNode } from 'react';
import { NavItems } from './components/NavItems';
import { Close, Menu } from '@mui/icons-material';
import { DrawerWrapper } from '../components/DrawerWrapper';

interface MobileMenuProps {
    open: boolean;
    setOpen: (value: boolean) => void;
    headerHeight: number;
}

const MenuItemsWrapper = ({ children, title }: { children: ReactNode; title: ReactNode }) => (
    <Box sx={{ mb: 6, '&:last-of-type': { mb: 0, '.MuiDivider-root': { display: 'none' } } }}>
        <Box sx={{ px: 2 }}>{children}</Box>

        <Divider sx={{ borderColor: '#F2F3F729', mt: 6 }} />
    </Box>
);

export const MobileMenu = ({ open, setOpen, headerHeight }: MobileMenuProps) => {
    return (
        <>
            {open ? (
                <Button
                    id="settings-button-mobile"
                    variant="surface"
                    sx={{ p: '7px 8px', minWidth: 'unset', ml: 2 }}
                    onClick={() => setOpen(false)}>
                    <SvgIcon
                        sx={{ color: '#FFFFF' }}
                        fontSize="small">
                        <Close />
                    </SvgIcon>
                </Button>
            ) : (
                <Button
                    id="settings-button-mobile"
                    variant="surface"
                    sx={{ p: '7px 8px', minWidth: 'unset', ml: 2 }}
                    onClick={() => setOpen(true)}>
                    <SvgIcon
                        sx={{ color: '#FFFFF' }}
                        fontSize="small">
                        <Menu />
                    </SvgIcon>
                </Button>
            )}

            <DrawerWrapper
                open={open}
                setOpen={setOpen}
                headerHeight={headerHeight}>
                <MenuItemsWrapper title="Menu">
                    <NavItems />
                </MenuItemsWrapper>
            </DrawerWrapper>
        </>
    );
};
