// src/components/Swap.tsx
import { Add, Lock, Remove } from '@mui/icons-material';
import {
    Box,
    Button,
    Grid,
    IconButton,
    Link as MaterialLink,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { UTXO } from 'opnet';
import React from 'react';
import { Link } from 'react-router-dom';
import {
    CompactableTypography,
    CompactMode,
} from '../../components/primitives/CompactibleTypography';
import { FormattedNumber } from '../../components/primitives/FormattedNumber';
import { checkRequiresApproval } from '../../components/transactions/utils';
import { ROUTES } from '../../configs/ui-config/Routes';
import useUserPoolData from '../../hooks/useUserPoolData';
import { useWeb3Context } from '../../hooks/useWeb3Context';
import { useSharedDependencies } from '../../providers/SharedDependenciesProvider';
import { useSnackbarContext } from '../../providers/SnackProvider';
import { UserPool } from '../../shared/types/pool/UserPool';
import { useRootStore } from '../../store/root';
import { getNetworkConfigKey, getProvider } from '../../utils/marketAndNetworkUtils';
import { fromBigInt, MAX_UINT256 } from '../../utils/tokenUtils';

const CurrentPoolsPanel: React.FC = () => {
    const { currentNetworkUserPoolData } = useUserPoolData();
    const { signAndBroadcastTransaction } = useWeb3Context();
    const { routerService, op20Service, approvedAmountService, poolService } =
        useSharedDependencies();
    const [userPoolData, currentNetworkConfig, account] = useRootStore((store) => [
        store.userPoolData,
        store.currentNetworkConfig,
        store.account,
    ]);
    const { showSnackbar } = useSnackbarContext();

    const handleRemoveLiquidity = async (pool: UserPool) => {
        try {
            if (pool.balance && pool.balance > 0n) {
                const amountToBigInt = pool.balance;
                const provider = getProvider(getNetworkConfigKey(currentNetworkConfig));
                const block = await provider.getBlockNumber();
                let utxo: UTXO[] = [];

                const allowance = await approvedAmountService.getApprovedAmount(
                    currentNetworkConfig,
                    account,
                    pool.pool.address,
                    currentNetworkConfig.addresses.ROUTER,
                );
                const approvalRequired = checkRequiresApproval({
                    approvedAmount: allowance,
                    amount: pool.balance,
                });

                if (approvalRequired) {
                    const allow = await op20Service.approve(currentNetworkConfig, {
                        user: account,
                        token: pool.pool.address,
                        spender: currentNetworkConfig.addresses.ROUTER,
                        amount: MAX_UINT256,
                    });
                    const signedApproval = await signAndBroadcastTransaction(allow, utxo);
                    utxo = signedApproval[2];

                    showSnackbar('approval succeeded', 'success');
                }

                const reserves = await poolService.GetReserveDataHumanized(
                    currentNetworkConfig,
                    pool.pool.address,
                    pool.pool.reserve0TokenAddress,
                    pool.pool.reserve1TokenAdress,
                );
                const totalSupply = await op20Service.getTotalSupply(
                    currentNetworkConfig,
                    pool.pool.address,
                );

                const amountAMin = (reserves.reserve0 * pool.balance) / totalSupply;
                const amountBMin = (reserves.reserve1 * pool.balance) / totalSupply;

                const t = await routerService.removeLiquidity(
                    currentNetworkConfig,
                    pool.pool.reserve0TokenAddress,
                    pool.pool.reserve1TokenAdress,
                    amountToBigInt,
                    amountAMin,
                    amountBMin,
                    account,
                    block + 10000n,
                    account,
                );

                const tx = await signAndBroadcastTransaction(t, utxo);
                if (tx[0].success && tx[1].success && tx[1].result) {
                    const link = currentNetworkConfig.explorerLinkBuilder({ tx: tx[1].result });
                    const message = (
                        <MaterialLink
                            sx={{ color: 'white' }}
                            href={link}
                            target="_blank">
                            <CompactableTypography compactMode={CompactMode.MD}>
                                {`tx: ${tx[1].result}`}
                            </CompactableTypography>
                        </MaterialLink>
                    );
                    showSnackbar('Removed Liquidity', 'success', 6000, message);
                } else {
                    const link = currentNetworkConfig.explorerLinkBuilder({ tx: tx[1].result });
                    const message = (
                        <MaterialLink
                            sx={{ color: 'white' }}
                            href={link}
                            target="_blank">
                            <CompactableTypography compactMode={CompactMode.MD}>
                                {`tx: ${tx[1].result}`}
                            </CompactableTypography>
                        </MaterialLink>
                    );
                    showSnackbar(`Error removing liquidity`, 'error', 6000, message);
                }
            }
        } catch (error) {
            showSnackbar('removal failed', 'error');
        }
    };

    return (
        <Paper
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                p: 3,
                boxSizing: 'border-box',
                position: 'relative',
                backgroundColor: '#1A1A1A',
                color: '#FFFFFF',
                borderRadius: 2,
                width: '100%',
            }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                <Box sx={{ pb: 2 }}>
                    <Typography
                        variant="main16"
                        sx={{ color: 'white' }}>
                        Your liquidity
                    </Typography>
                </Box>
                <Grid
                    container
                    spacing={2}
                    justifyContent="center">
                    <Grid item>
                        <Link to={ROUTES.poolCreate}>
                            <Button
                                variant="contained"
                                sx={{ backgroundColor: '#6a1b9a' }}>
                                Create Pair
                            </Button>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link to={ROUTES.poolImport}>
                            <Button
                                variant="contained"
                                sx={{ backgroundColor: '#6a1b9a' }}>
                                Import
                            </Button>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link to={ROUTES.poolCreate}>
                            <Button
                                variant="contained"
                                sx={{ backgroundColor: '#6a1b9a' }}>
                                Add Liquidity
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            </Box>
            {!userPoolData ? (
                <Box>
                    <Typography sx={{ mt: 2 }}>No liquidity found.</Typography>
                </Box>
            ) : (
                <Box>
                    <TableContainer sx={{ width: '100%' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ color: '#FFFFFF', fontSize: 12 }}>
                                        Pool
                                    </TableCell>
                                    <TableCell sx={{ color: '#FFFFFF', fontSize: 12 }}>
                                        Amount
                                    </TableCell>
                                    <TableCell sx={{ color: '#FFFFFF', fontSize: 12 }}>
                                        Actions
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {Array.from(currentNetworkUserPoolData.entries()).length === 0 ? (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={3}>
                                            <Typography sx={{ color: '#FFFFFF' }}>
                                                Loading...
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            ) : (
                                <TableBody>
                                    {Array.from(currentNetworkUserPoolData.entries()).map(
                                        ([poolAddress, poolData]) => (
                                            <TableRow key={poolAddress}>
                                                <TableCell>
                                                    <Typography
                                                        variant="main16"
                                                        sx={{ color: '#FFFFFF' }}>
                                                        {`${poolData.pool.reserve0TokenSymbol}-${poolData.pool.reserve1TokenSymbol}`}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        variant="main16"
                                                        sx={{ color: '#FFFFFF' }}>
                                                        <FormattedNumber
                                                            value={fromBigInt(
                                                                poolData.balance,
                                                                8,
                                                            ).toString()}
                                                            visibleDecimals={5}
                                                        />
                                                    </Typography>
                                                </TableCell>

                                                <TableCell sx={{ color: '#FFFFFF' }}>
                                                    <IconButton
                                                        sx={{ color: '#FFFFFF' }}
                                                        disabled={true}>
                                                        <Lock />
                                                    </IconButton>
                                                    <IconButton
                                                        sx={{ color: '#FFFFFF' }}
                                                        onClick={() =>
                                                            handleRemoveLiquidity(poolData)
                                                        }>
                                                        <Remove />
                                                    </IconButton>
                                                    <IconButton
                                                        sx={{ color: '#FFFFFF' }}
                                                        onClick={() =>
                                                            (window.location.href = '/pools/create')
                                                        }>
                                                        <Add />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ),
                                    )}
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Box>
            )}
        </Paper>
    );
};

export default CurrentPoolsPanel;
