import { useRootStore } from '../store/root';

const useUserPoolData = () => {
    const { currentNetworkConfig, userPools, userPoolData } = useRootStore((store) => ({
        currentNetworkConfig: store.currentNetworkConfig,
        userPools: store.userPools,
        userPoolData: store.userPoolData,
    }));

    const network = currentNetworkConfig.name;
    const currentNetworkUserPools = userPools.get(network) || [];
    const currentNetworkUserPoolData = userPoolData.get(network) || new Map();

    return { currentNetworkUserPools, currentNetworkUserPoolData };
};

export default useUserPoolData;
