import { Box, CssBaseline } from '@mui/material';
import React from 'react';

import AppHeader from './AppHeader';
import { AppFooter } from './AppFooter';

const MainLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <>
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    justifyContent: 'space-between',
                }}>
                <AppHeader />
                <Box
                    component="main"
                    sx={{ flexGrow: 1 }}>
                    {children}
                </Box>
                <AppFooter />
            </Box>
        </>
    );
};

export default MainLayout;
