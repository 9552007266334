import { NetworkConfig } from '../shared/types/NetworkConfig';

export const queryKeysFactory = {
    user: (user: string) => [user],
    networkConfig: (networkConfig: NetworkConfig) => [networkConfig.network, 'networkConfig'],
    topTokens: (networkConfig: NetworkConfig) => [networkConfig, 'topTokens'],
    tokenMetdata: (contractAddress: string) => [contractAddress, 'tokenMetadata'],
    approvedAmount: (
        user: string,
        token: string,
        spender: string,
        networkConfig: NetworkConfig,
    ) => [
        ...queryKeysFactory.user(user),
        ...queryKeysFactory.networkConfig(networkConfig),
        token,
        spender,
        'approvedAmount',
    ],
    tokenBalance: (contractAddress: string, address: string) => [
        ...queryKeysFactory.user(address),
        contractAddress,
        'tokenBalance',
    ],
    userUxtoData: (user: string) => [...queryKeysFactory.user(user), 'userUxtoData'],
};

export const POLLING_INTERVAL = 60000;
