import { Settings } from '@mui/icons-material';
import { Button, SvgIcon } from '@mui/material';
import React, { useState } from 'react';
import NetworkSettingsModal from '../components/modals/NetworkSettingsModal';

export function SettingsMenu() {
    const [settingsOpen, setSettingsOpen] = useState(false);

    const handleSettingsClick = () => {
        setSettingsOpen(true);
    };

    const handleClose = () => {
        setSettingsOpen(false);
    };

    return (
        <>
            <Button
                variant="outlined"
                aria-label="settings"
                id="settings-button"
                aria-controls={settingsOpen ? 'settings-menu' : undefined}
                aria-expanded={settingsOpen ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleSettingsClick}
                sx={{ p: '7px 8px', minWidth: 'unset', ml: 2, borderColor: 'white' }}>
                <SvgIcon fontSize="small">
                    <Settings sx={{ color: 'white' }} />
                </SvgIcon>
            </Button>

            <NetworkSettingsModal
                open={settingsOpen}
                handleClose={handleClose}
            />
        </>
    );
}
