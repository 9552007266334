import { ProtocolAction } from '../shared/types/transactions/ProtocolAction';
import { RootStore } from './root';
import { StateCreator } from 'zustand';
import { produce } from 'immer';

export type Transactions = {
    [network: string]: {
        [txHash: string]: TransactionDetails;
    };
};

export type TransactionDetails = {
    action?: ProtocolAction | string;
    txState?: TransactionState;
    previousState?: string;
    newState?: string;
};

type TransactionState = 'success' | 'failed';

type TransactionContext = {
    network?: string | null;
};

export interface TransactionsSlice {
    transactions: Transactions;
    addTransaction: (
        txHash: string,
        transaction: TransactionDetails,
        context?: TransactionContext,
    ) => void;
}

export const createTransactionsSlice: StateCreator<
    RootStore,
    [['zustand/subscribeWithSelector', never], ['zustand/devtools', never]],
    [],
    TransactionsSlice
> = (set, get) => {
    return {
        transactions: {}, // Change from [] to {}
        addTransaction: (txHash, transaction, context = {}) => {
            const network = context.network ?? get().currentNetwork;
            set((state) =>
                produce(state, (draft) => {
                    if (!draft.transactions[network]) {
                        draft.transactions[network] = {};
                    }
                    draft.transactions[network][txHash] = {
                        ...transaction,
                    };
                }),
            );
        },
    };
};
