import React, { createContext, useContext, useState } from 'react';
import { TxErrorType } from '../../src/configs/ui-config/errorMappings';
import { BasicSnackBar, BasicSnackBarProps } from '../components/primitives/BasicSnackBar';

export interface SnackbarContextType extends Omit<BasicSnackBarProps, 'open' | 'onClose'> {
    showSnackbar: (
        message: string,
        severity?: 'success' | 'error' | 'warning' | 'info',
        autoHideDuration?: number,
        content?: React.ReactNode,
    ) => void;
    closeSnackbar: () => void;
    txError?: TxErrorType;
    setTxError: (error: TxErrorType | undefined) => void;
}

export const SnackbarContext = createContext<SnackbarContextType>({} as SnackbarContextType);

export const SnackbarProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState<'success' | 'error' | 'warning' | 'info'>('info');
    const [autoHideDuration, setAutoHideDuration] = useState(6000);
    const [content, setContent] = useState<React.ReactNode>(null);
    const [txError, setTxError] = useState<TxErrorType>();

    const showSnackbar = (
        message: string,
        severity: 'success' | 'error' | 'warning' | 'info' = 'info',
        autoHideDuration = 6000,
        content?: React.ReactNode,
    ) => {
        setMessage(message);
        setSeverity(severity);
        setAutoHideDuration(autoHideDuration);
        setContent(content);
        setOpen(true);
    };

    const closeSnackbar = () => {
        setOpen(false);
    };

    return (
        <SnackbarContext.Provider
            value={{
                showSnackbar,
                closeSnackbar,
                message,
                severity,
                autoHideDuration,
                children,
                txError,
                setTxError,
            }}>
            {children}
            <BasicSnackBar
                open={open}
                message={message}
                onClose={closeSnackbar}
                severity={severity}
                autoHideDuration={autoHideDuration}>
                {content}
            </BasicSnackBar>
        </SnackbarContext.Provider>
    );
};

export const useSnackbarContext = () => {
    const context = useContext(SnackbarContext);

    if (context === undefined) {
        throw new Error('useSnackbarContext must be used within a SnackbarProvider');
    }

    return context;
};
