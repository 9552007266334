import { JSONRpcProvider } from 'opnet';
import { NetworkKey } from '../configs/ui-config/NetworkKey';
import {
    BaseNetworkConfig,
    networksConfig as _networkConfigs,
} from '../configs/ui-config/networksConfig';
import {
    ExplorerLinkBuilderConfig,
    ExplorerLinkBuilderProps,
    NetworkConfig,
} from '../shared/types/NetworkConfig';

/**
 * Network Utils
 */
export const PROD_ENV = !process.env.REACT_APP_MODE || process.env.REACT_APP_MODE === 'prod';
export const ENABLE_TESTNET =
    PROD_ENV && global?.window?.localStorage.getItem('testnetsEnabled') === 'true';

/**
 * Generates network configs based on networkConfigs & testnet settings.
 */
export const getNetworkConfigKey = (currentNetworkConfig: NetworkConfig): NetworkKey => {
    const networkKey = Object.keys(networkConfigs).find((key) => {
        const config = networkConfigs[key as NetworkKey];
        return JSON.stringify(config) === JSON.stringify(currentNetworkConfig);
    });

    if (!networkKey) {
        throw new Error('No matching network configuration found.');
    }

    return networkKey as NetworkKey;
};

export const networkConfigs = Object.keys(_networkConfigs).reduce(
    (acc, value) => {
        const key = value as keyof typeof _networkConfigs;
        acc[key] = _networkConfigs[key];
        // Additional logic can be added here if needed
        return acc;
    },
    {} as { [key in NetworkKey]: BaseNetworkConfig },
);

export function getNetworkConfig(networkKey: NetworkKey): NetworkConfig {
    const config = networkConfigs[networkKey];

    return {
        ...config,
        explorerLinkBuilder: linkBuilder({ baseUrl: config.explorerLink }),
    };
}

export const getProvider = (network: NetworkKey): JSONRpcProvider => {
    const config = getNetworkConfig(network);

    if (!config.privateJsonRPCUrl) {
        throw new Error('No private JSON RPC URL found');
    }

    const provider: JSONRpcProvider = new JSONRpcProvider(config.privateJsonRPCUrl);
    return provider;
};

//will filter here
export const availableNetworks: { [key: string]: BaseNetworkConfig } = Object.fromEntries(
    Object.entries(_networkConfigs).filter(([key, config]) => config.isAvailable),
);

/** Private */
const linkBuilder =
    ({
        baseUrl,
        addressPrefix = 'address',
        txPrefix = 'transactions',
    }: ExplorerLinkBuilderConfig) =>
    ({ tx, address }: ExplorerLinkBuilderProps): string => {
        if (tx) {
            return `${baseUrl}/${txPrefix}/${tx}`;
        }
        if (address) {
            return `${baseUrl}/${addressPrefix}/${address}`;
        }
        return baseUrl;
    };
