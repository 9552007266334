import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const GenericTokenIcon = ({ sx, ...rest }: SvgIconProps) => {
    return (
        <SvgIcon
            sx={{ ...sx }}
            xmlns="http://www.w3.org/2000/svg"
            aria-label="Dot"
            viewBox="0 0 128 128"
            fill="currentcolor"
            {...rest}>
            <circle
                cx="64"
                cy="64"
                r="64"
                fill="#66C2A5"
            />
            <circle
                cx="32"
                cy="32"
                r="16"
                fill="#FC8D62"
            />
            <circle
                cx="96"
                cy="32"
                r="16"
                fill="#FC8D62"
            />
            <circle
                cx="32"
                cy="96"
                r="16"
                fill="#FC8D62"
            />
            <circle
                cx="96"
                cy="96"
                r="16"
                fill="#FC8D62"
            />
            <circle
                cx="64"
                cy="64"
                r="16"
                fill="#E78AC3"
            />
        </SvgIcon>
    );
};
