import React, { useContext } from 'react';
import { useUserPoolDataSubscription } from '../store/root';

interface BackgroundDataProviderContextType {
    refetchUserPoolData?: () => Promise<void> | Promise<void[]>;
}

const BackgroundDataProviderContext = React.createContext<BackgroundDataProviderContextType>(
    {} as BackgroundDataProviderContextType,
);

export const BackgroundDataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const refetchUserPoolData = useUserPoolDataSubscription();

    return (
        <BackgroundDataProviderContext.Provider value={{ refetchUserPoolData }}>
            {children}
        </BackgroundDataProviderContext.Provider>
    );
};

export const useBackgroundDataProvider = () => useContext(BackgroundDataProviderContext);
