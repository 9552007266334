// src/components/Swap.tsx
import React from 'react';
import { Button, Typography } from '@mui/material';
import { useWeb3Context } from '../../hooks/useWeb3Context';
import { CompactableTypography } from '../primitives/CompactibleTypography';
import { useIsWrongNetwork } from '../../hooks/useIsWrongNetwork';

const ConnectWalletButton: React.FC = () => {
    const { connectWallet, disconnectWallet, currentAccount, connected } = useWeb3Context();
    const { isWrongNetwork } = useIsWrongNetwork();

    const handleClick = () => {
        if (connected) {
            disconnectWallet();
        } else {
            connectWallet();
        }
    };

    return (
        <Button
            variant="contained"
            color="secondary"
            className="bg-pink-600 text-white"
            onClick={() => handleClick()}>
            {isWrongNetwork ? (
                <Typography>WRONG NETWORK</Typography>
            ) : connected ? (
                <CompactableTypography>{currentAccount}</CompactableTypography>
            ) : (
                <Typography>Connect</Typography>
            )}
        </Button>
    );
};

export default ConnectWalletButton;
