// src/components/SwapInputBox.tsx
import React from 'react';
import { Box, InputBase, InputBaseProps, SxProps, Theme } from '@mui/material';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
    value: string;
}

export const NumberFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
    function NumberFormatCustom(props, ref) {
        const { onChange, ...other } = props;

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    if (values.value !== props.value)
                        onChange({
                            target: {
                                name: props.name,
                                value: values.value || '',
                            },
                        });
                }}
                thousandSeparator
                valueIsNumericString
                allowNegative={false}
            />
        );
    },
);

interface NumericInputProps extends InputBaseProps {
    sx?: SxProps<Theme>;
}

const NumericInput: React.FC<NumericInputProps> = ({ sx, ...rest }) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
            <InputBase
                sx={{
                    ...sx,
                }}
                fullWidth
                placeholder="0"
                {...rest}
                // eslint-disable-next-line
                inputComponent={NumberFormatCustom as any}
            />
        </Box>
    );
};

export default NumericInput;
