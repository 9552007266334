import { Address } from '@btc-vision/bsi-binary';
import { JSONRpcProvider } from 'opnet';
import { NetworkKey } from '../configs/ui-config/NetworkKey';
import { NetworkConfig } from '../shared/types/NetworkConfig';
import { Op20Service } from './Op20Service';

export class ApprovedAmountService {
    constructor(private readonly getProvider: (network: NetworkKey) => JSONRpcProvider) {}

    private async getOp20Service() {
        return new Op20Service(this.getProvider);
    }

    async getApprovedAmount(
        networkConfig: NetworkConfig,
        user: Address,
        token: Address,
        spender: Address,
    ): Promise<bigint> {
        const op20TokenService = await this.getOp20Service();
        const amt = await op20TokenService.allowance(networkConfig, token, user, spender);
        return amt;
    }
}
