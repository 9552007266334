import { Box, ClickAwayListener, Tooltip } from '@mui/material';
import React, { JSXElementConstructor, ReactElement, ReactNode, useState } from 'react';

interface ContentWithTooltipProps {
    children: ReactNode;
    // eslint-disable-next-line
    tooltipContent: ReactElement<any, string | JSXElementConstructor<any>>;
    placement?: 'top' | 'bottom';
    withoutHover?: boolean;
    open?: boolean;
    setOpen?: (value: boolean) => void;
    offset?: [number, number];
}

export const ContentWithTooltip = ({
    children,
    tooltipContent,
    placement = 'top',
    withoutHover,
    open,
    setOpen,
    offset,
}: ContentWithTooltipProps) => {
    const [openTooltip, setOpenTooltip] = useState(false);

    const formattedOpen = typeof open !== 'undefined' ? open : openTooltip;
    const toggleOpen = () =>
        typeof setOpen !== 'undefined' ? setOpen(!formattedOpen) : setOpenTooltip(!formattedOpen);
    const handleClose = () =>
        typeof setOpen !== 'undefined' ? setOpen(false) : setOpenTooltip(false);

    return (
        <Tooltip
            open={formattedOpen}
            onClose={handleClose}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            placement={placement}
            componentsProps={{
                popper: {
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: offset ?? [],
                            },
                        },
                    ],
                    onClick: (e) => {
                        e.stopPropagation();
                    },
                },
            }}
            title={
                <ClickAwayListener
                    mouseEvent="onMouseDown"
                    touchEvent="onTouchStart"
                    onClickAway={handleClose}>
                    <Box
                        sx={{
                            py: 4,
                            px: 6,
                            fontSize: '12px',
                            lineHeight: '16px',
                            a: {
                                fontSize: '12px',
                                lineHeight: '16px',
                                fontWeight: 500,
                                '&:hover': { textDecoration: 'underline' },
                            },
                        }}>
                        {tooltipContent}
                    </Box>
                </ClickAwayListener>
            }
            arrow>
            <Box
                sx={{
                    display: 'inline-flex',
                    cursor: 'pointer',
                    transition: 'all 0.2s ease',
                    '&:hover': { opacity: withoutHover ? 1 : formattedOpen ? 1 : 0.5 },
                }}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    toggleOpen();
                }}>
                {children}
            </Box>
        </Tooltip>
    );
};
