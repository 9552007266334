import { IWrapParameters } from '@btc-vision/transaction';
import { useCallback } from 'react';
import { useRootStore } from '../store/root';
import { satoshisFromBtc } from '../utils/tokenUtils';
import { useWeb3Context } from './useWeb3Context';

export const UseWrapBitcoin = (amount: string) => {
    const { fetchWrapParameters, wrap } = useWeb3Context();
    const [account, walletUtxos, signer, currentNetworkConfig] = useRootStore((store) => [
        store.account,
        store.walletUtxos,
        store.signer,
        store.currentNetworkConfig,
    ]);

    const handleWrapBitcoin = useCallback(async () => {
        const amountBigInt = satoshisFromBtc(amount);

        const generationParameters = await fetchWrapParameters(amountBigInt);
        if (!generationParameters) {
            throw new Error('No generation parameters found');
        }

        if (!signer) {
            throw new Error('No signer found');
        }

        const wrapParameters: IWrapParameters = {
            from: account,
            utxos: walletUtxos,
            signer,
            network: currentNetworkConfig.network,
            feeRate: 60,
            priorityFee: 20_000n,
            amount: amountBigInt,
            generationParameters: generationParameters,
        };

        const t = await wrap(wrapParameters);
        console.log(t);
    }, [amount, account, walletUtxos, signer, currentNetworkConfig, fetchWrapParameters, wrap]);

    return handleWrapBitcoin;
};

export const UseUnWrapBitcoin = async (amount: bigint) => {
    const { fetchWrapParameters } = useWeb3Context();

    const handleWrapBitcoin = useCallback(async () => {
        const generationParameters = await fetchWrapParameters(amount);
        if (!generationParameters) {
            throw new Error('No generation parameters found');
        }
    }, [amount, fetchWrapParameters]);

    return handleWrapBitcoin;
};
