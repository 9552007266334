import React, { useState } from 'react';
import { Box, Typography, Button, InputBase } from '@mui/material';
import useBitcoinBalance from '../../hooks/useBitcoinBalance';
import { UseWrapBitcoin } from '../../hooks/useWrapBitcoin';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { BasicModal } from '../primitives/BasicModal';

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
    value: string;
}

export const NumberFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
    function NumberFormatCustom(props, ref) {
        const { onChange, ...other } = props;

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    if (values.value !== props.value)
                        onChange({
                            target: {
                                name: props.name,
                                value: values.value || '',
                            },
                        });
                }}
                thousandSeparator
                valueIsNumericString
                allowNegative={false}
            />
        );
    },
);

interface WrapBitcoinModalProps {
    open: boolean;
    handleClose: () => void;
    handleWrap: (amount: number) => void;
}

const WrapBitcoinModal: React.FC<WrapBitcoinModalProps> = ({ open, handleClose, handleWrap }) => {
    const { bitcoin } = useBitcoinBalance();
    const [amount, setAmount] = useState('');

    const handleAmountChange = (value: string) => {
        setAmount(value);
    };

    const handleWrapBitcoin = UseWrapBitcoin(amount);

    return (
        <BasicModal
            open={open}
            setOpen={handleClose}>
            <Box
                sx={{
                    width: '100%',
                    color: '#fff',
                    p: 4,
                    borderRadius: 2,
                }}>
                <Typography
                    variant="h2"
                    color="text.primary">
                    Wrap
                </Typography>
                <Typography sx={{ mt: 2 }}>BTC Balance: {bitcoin}</Typography>
                <InputBase
                    fullWidth
                    placeholder="0.0"
                    sx={{
                        height: '100px',
                        fontSize: '25px',
                    }}
                    onChange={(e) => handleAmountChange(e.target.value)}
                    // eslint-disable-next-line
                    inputComponent={NumberFormatCustom as any}
                />
                <Button
                    disabled={true}
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleWrapBitcoin}
                    sx={{ mt: 2, backgroundColor: '#6a1b9a' }}>
                    Wrap BTC
                </Button>
            </Box>
        </BasicModal>
    );
};

export default WrapBitcoinModal;
