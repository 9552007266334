export const getQueryParameter = (key: string) => {
    if (typeof window !== 'undefined' && 'URLSearchParams' in window) {
        const proxy = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop as string),
        });
        return (proxy as unknown as { [key: string]: string })[key];
    }
};

export const textCenterEllipsis = (str: string, from: number, to: number) => {
    return `${str.substr(0, from)}...${str.substr(str.length - to, str.length)}`;
};

export function hexToAscii(_hex: string): string {
    const hex = _hex.toString();
    let str = '';
    for (let n = 0; n < hex.length; n += 2) {
        str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
    }
    return str;
}
