import React from 'react';
import { Box, Typography, MenuItem, Select, FormControl, SelectChangeEvent } from '@mui/material';
import { availableNetworks } from '../../utils/marketAndNetworkUtils';
import { useRootStore } from '../../store/root';
import { NetworkKey } from '../../configs/ui-config/NetworkKey';
import { BasicModal } from '../primitives/BasicModal';

interface NetworkSettingsModalModalProps {
    open: boolean;
    handleClose: () => void;
}

const NetworkSettingsModal: React.FC<NetworkSettingsModalModalProps> = ({ open, handleClose }) => {
    const [currentNetworkConfig, setCurrentNetworkConfig] = useRootStore((store) => [
        store.currentNetworkConfig,
        store.setCurrentNetwork,
    ]);

    const handleChange = (event: SelectChangeEvent<string>) => {
        const selectedNetworkKey = event.target.value as NetworkKey;
        setCurrentNetworkConfig(selectedNetworkKey);
        window.location.href = '/';
    };

    return (
        <BasicModal
            open={open}
            setOpen={handleClose}>
            <Box
                sx={{
                    color: '#fff',
                    p: 4,
                    borderRadius: 2,
                }}>
                <Box sx={{ pb: 2 }}>
                    <Typography
                        variant="h2"
                        color="text.primary">
                        Global Settings
                    </Typography>
                </Box>
                <Box>
                    <Typography>Network</Typography>
                    <FormControl
                        fullWidth
                        variant="outlined">
                        <Select
                            value={
                                Object.keys(availableNetworks).find(
                                    (key) =>
                                        availableNetworks[key].name === currentNetworkConfig.name,
                                ) || ''
                            }
                            labelId="network-select-label"
                            onChange={handleChange}>
                            {Object.keys(availableNetworks).map((network) => {
                                const config =
                                    availableNetworks[network as keyof typeof availableNetworks];
                                return (
                                    <MenuItem
                                        key={network}
                                        value={network}>
                                        <Typography variant="main16">{config.name} </Typography>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Box>
            </Box>
        </BasicModal>
    );
};

export default NetworkSettingsModal;
