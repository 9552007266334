import { MenuItem } from '../../shared/types/menuItem';
import { ROUTES } from './Routes';

export const navigation: MenuItem[] = [
    {
        link: ROUTES.swap,
        title: `Swap`,
    },
    {
        link: ROUTES.pool,
        title: `Pool`,
    },
];
