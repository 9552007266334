import React, { ReactNode, useState } from 'react';
import { Box, Container, IconButton, Paper, PaperProps } from '@mui/material';
import { CogIcon } from '@heroicons/react/16/solid';
import SlippageSettingsPopover from '../../components/modals/TransactionSettingsModule';
import SwapPanel from './SwapPanel';

interface SwapContainerProps extends PaperProps {
    loading?: boolean;
    description?: ReactNode;
}

const SwapContainer: React.FC = ({ loading, description, sx, ...rest }: SwapContainerProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleSettingsClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const openPopover = Boolean(anchorEl);

    return (
        <Container
            maxWidth="sm"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 4,
                mb: 4,
                width: '100%',
                height: '100%',
                padding: '0 16px',
                boxSizing: 'border-box',
            }}>
            <Paper
                {...rest}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    p: { xs: 2, sm: 4 },
                    width: '100%',
                    maxWidth: '600px',
                    boxSizing: 'border-box',
                    position: 'relative',
                }}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: { xs: 4, sm: 8 },
                        right: { xs: 4, sm: 8 },
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                    <IconButton
                        sx={{
                            height: '40px',
                            width: '40px',
                            color: '#ffffff',
                        }}
                        onClick={handleSettingsClick}>
                        <CogIcon />
                    </IconButton>
                </Box>
                <SwapPanel />
            </Paper>
            <SlippageSettingsPopover
                anchorEl={anchorEl}
                open={openPopover}
                onClose={handleClose}
            />
        </Container>
    );
};

export default SwapContainer;
