import React, { createContext, useContext } from 'react';
import invariant from 'tiny-invariant';
import { ApprovedAmountService } from '../services/ApprovedAmountService';
import { FactoryService } from '../services/FactoryService';
import { Op20Service } from '../services/Op20Service';
import { OpNetService } from '../services/OpNetService';
import { PoolService } from '../services/PoolService';
import { RouterService } from '../services/RouterService';
import { getProvider } from '../utils/marketAndNetworkUtils';

interface SharedDependenciesContextInterface {
    factoryService: FactoryService;
    poolService: PoolService;
    routerService: RouterService;
    op20Service: Op20Service;
    approvedAmountService: ApprovedAmountService;
    opNetService: OpNetService;
}

const SharedDependenciesContext = createContext<SharedDependenciesContextInterface | null>(null);

export const SharedDependenciesProvider: React.FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    //staking
    // const getStakeProvider = (chainId: number) => {
    //   const networkConfig = getNetworkConfig(chainId);
    //   const isStakeFork =
    //     networkConfig.isFork && networkConfig.underlyingChainId === stakeConfig.chainId;
    //   return isStakeFork ? getProvider(chainId) : getProvider(stakeConfig.chainId);
    // };

    // services
    const factoryService = new FactoryService(getProvider);
    const poolService = new PoolService(getProvider);
    const routerService = new RouterService(getProvider);
    const op20Service = new Op20Service(getProvider);
    const approvedAmountService = new ApprovedAmountService(getProvider);
    const opNetService = new OpNetService(getProvider);

    return (
        <SharedDependenciesContext.Provider
            value={{
                factoryService,
                poolService,
                routerService,
                op20Service,
                approvedAmountService,
                opNetService,
            }}>
            {children}
        </SharedDependenciesContext.Provider>
    );
};

export const useSharedDependencies = () => {
    const context = useContext(SharedDependenciesContext);
    invariant(context, 'Component should be wrapper inside a <SharedDependenciesProvider />');
    return context;
};
