import { Box, Container } from '@mui/material';
import React, { ReactNode } from 'react';

export interface ContentContainerProps {
    children: ReactNode;
}

const ContentContainer = ({ children }: ContentContainerProps) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                boxSizing: 'border-box',
            }}>
            <Container
                maxWidth="lg"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    boxSizing: 'border-box',
                }}>
                {children}
            </Container>
        </Box>
    );
};

export default ContentContainer;
