import { useState, useEffect } from 'react';
import { useRootStore } from '../store/root';
import { btcFromSatoshis } from '../utils/tokenUtils';

const useBitcoinBalance = () => {
    const [bitcoin, setBitcoin] = useState('');
    const [bitcoinBalance] = useRootStore((store) => [store.bitcoinBalance]);
    useEffect(() => {
        if (bitcoinBalance) {
            const bitcoin = btcFromSatoshis(bitcoinBalance.total);
            setBitcoin(bitcoin);
        }
    }, [bitcoinBalance]);

    return { bitcoin };
};

export default useBitcoinBalance;
