import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { IconButton, Paper, SvgIcon } from '@mui/material';
import { XMarkIcon } from '@heroicons/react/24/outline';

export interface BasicModalProps {
    open: boolean;
    children: React.ReactNode;
    setOpen: (value: boolean) => void;
    withCloseButton?: boolean;
    contentMaxWidth?: number;
}

export const BasicModal = ({
    open,
    setOpen,
    withCloseButton = true,
    contentMaxWidth = 420,
    children,
    ...props
}: BasicModalProps) => {
    const handleClose = () => setOpen(false);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backdropFilter: 'blur(5px)',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}
            onClick={(e) => {
                e.stopPropagation();
            }}
            {...props}
            data-cy={'Modal'}>
            <Paper
                sx={{
                    position: 'relative',
                    margin: '10px',
                    overflowY: 'auto',
                    width: '100%',
                    maxWidth: { xs: '359px', sm: `${contentMaxWidth}px` },
                    maxHeight: 'calc(100vh - 20px)',
                    padding: '20px',
                    borderRadius: '15px',
                    border: '1px solid #1E1243',
                    background: 'linear-gradient(90deg, #120B26 0%, #100A22 100%)',
                    boxShadow: '0px 4px 100px 20px rgba(189, 48, 204, 0.20)',
                    color: '#fff',
                }}>
                {withCloseButton && (
                    <Box sx={{ position: 'absolute', top: '15px', right: '15px', zIndex: 5 }}>
                        <IconButton
                            sx={{
                                borderRadius: '50%',
                                padding: 0,
                                minWidth: 0,
                            }}
                            onClick={handleClose}
                            data-cy={'close-button'}>
                            <SvgIcon sx={{ fontSize: '20px', color: '#fff' }}>
                                <XMarkIcon />
                            </SvgIcon>
                        </IconButton>
                    </Box>
                )}
                {children}
            </Paper>
        </Modal>
    );
};
