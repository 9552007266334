// src/components/Header.tsx
import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Link,
    Slide,
    SvgIcon,
    Typography,
    useMediaQuery,
    useScrollTrigger,
    useTheme,
} from '@mui/material';
import ConnectWalletButton from '../components/buttons/ConnectWalletButton';
import { useRootStore } from '../store/root';
import { PROD_ENV } from '../utils/marketAndNetworkUtils';
import { ContentWithTooltip } from '../components/ContentWithToolTip';
import { InformationCircleIcon } from '@heroicons/react/16/solid';
import { NavItems } from './components/NavItems';
import { MobileMenu } from './MobileMenu';
import { SettingsMenu } from './SettingsMenu';
import { FEATURE_TESTNET_ENABLED } from '../configs/ui-config/uiConfig';
import WrapBitcoinButton from '../components/buttons/WrapBitcoinButton';

interface Props {
    children: React.ReactElement;
}

function HideOnScroll({ children }: Props) {
    const { breakpoints } = useTheme();
    const md = useMediaQuery(breakpoints.down('md'));
    const trigger = useScrollTrigger({ threshold: md ? 160 : 80 });

    return (
        <Slide
            appear={false}
            direction="down"
            in={!trigger}>
            {children}
        </Slide>
    );
}

const AppHeader: React.FC = () => {
    const currentNetworkConfig = useRootStore((root) => root.currentNetworkConfig);
    const { breakpoints } = useTheme();
    const md = useMediaQuery(breakpoints.down('md'));
    const sm = useMediaQuery(breakpoints.down('sm'));

    const [mobileDrawerOpen, setMobileDrawerOpen] = useRootStore((state) => [
        state.mobileDrawerOpen,
        state.setMobileDrawerOpen,
    ]);

    const [walletWidgetOpen, setWalletWidgetOpen] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    useEffect(() => {
        if (mobileDrawerOpen && !md) {
            setMobileDrawerOpen(false);
        }
        if (walletWidgetOpen) {
            setWalletWidgetOpen(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [md]);

    const toggleMobileMenu = (state: boolean) => {
        if (md) setMobileDrawerOpen(state);
        setMobileMenuOpen(state);
    };

    const testnetTooltip = (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: 1 }}>
            <Typography variant="subheader1">Testnet mode is ON</Typography>
            <Typography variant="description">The app is running in testnet mode. </Typography>
        </Box>
    );

    const headerHeight = 48;

    return (
        <>
            <HideOnScroll>
                <Box
                    component="header"
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    sx={(theme) => ({
                        height: headerHeight,
                        position: 'sticky',
                        top: 0,
                        transition: theme.transitions.create('top'),
                        zIndex: theme.zIndex.appBar,
                        padding: {
                            xs:
                                mobileMenuOpen || walletWidgetOpen
                                    ? '8px 20px'
                                    : '8px 8px 8px 20px',
                            xsm: '8px 20px',
                        },
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'space-between',
                        boxShadow: 'inset 0px -1px 0px rgba(242, 243, 247, 0.16)',
                    })}>
                    <Box
                        component={Link}
                        href="/"
                        aria-label="Go to homepage"
                        sx={{
                            lineHeight: 0,
                            mr: 3,
                            transition: '0.3s ease all',
                            '&:hover': { opacity: 0.7 },
                        }}
                        onClick={() => setMobileMenuOpen(false)}>
                        <img
                            src="/images/logos/moto_horizontal_white.svg"
                            alt="MOTOSWAP"
                            height={30}
                        />
                    </Box>
                    <Box sx={{ mr: sm ? 1 : 3 }}>
                        {currentNetworkConfig.isTestnet && (
                            <ContentWithTooltip
                                tooltipContent={testnetTooltip}
                                offset={[0, -4]}
                                withoutHover>
                                <Button
                                    variant="surface"
                                    size="small"
                                    color="primary"
                                    sx={{
                                        backgroundColor: '#B6509E',
                                        '&:hover, &.Mui-focusVisible': {
                                            backgroundColor: 'rgba(182, 80, 158, 0.7)',
                                        },
                                    }}>
                                    {currentNetworkConfig.name}
                                    <SvgIcon sx={{ marginLeft: '2px', fontSize: '16px' }}>
                                        <InformationCircleIcon />
                                    </SvgIcon>
                                </Button>
                            </ContentWithTooltip>
                        )}
                    </Box>
                    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                        <NavItems />
                    </Box>
                    <Box sx={{ flexGrow: 1 }} />
                    {!mobileMenuOpen && (
                        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                            <WrapBitcoinButton />
                        </Box>
                    )}
                    {!mobileMenuOpen && (
                        <Box ml={3}>
                            <ConnectWalletButton />
                        </Box>
                    )}

                    {PROD_ENV && FEATURE_TESTNET_ENABLED && (
                        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                            <SettingsMenu />
                        </Box>
                    )}

                    {!walletWidgetOpen && (
                        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                            <MobileMenu
                                open={mobileMenuOpen}
                                setOpen={toggleMobileMenu}
                                headerHeight={headerHeight}
                            />
                        </Box>
                    )}
                </Box>
            </HideOnScroll>
        </>
    );
};

export default AppHeader;
