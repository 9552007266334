import { JSONRpcProvider } from 'opnet';
import { NetworkKey } from '../configs/ui-config/NetworkKey';
import { NetworkConfig } from '../shared/types/NetworkConfig';
import { UserPool } from '../shared/types/pool/UserPool';
import { Op20Service } from './Op20Service';
import { PoolService } from './PoolService';

export class UiPoolService {
    constructor(private readonly getProvider: (network: NetworkKey) => JSONRpcProvider) {}

    private getPoolService() {
        return new PoolService(this.getProvider);
    }

    private getOp20Service() {
        return new Op20Service(this.getProvider);
    }

    async getUserPoolDataHumanized(
        currentNetworkConfig: NetworkConfig,
        poolAddress: string,
        user: string,
    ) {
        const poolService = this.getPoolService();
        const op20Service = this.getOp20Service();
        const balance = await op20Service.balanceOf(currentNetworkConfig, poolAddress, user);
        const token0 = await poolService.getToken0(currentNetworkConfig, poolAddress);
        const token0Metadata = await op20Service.GetTokenMetadata(currentNetworkConfig, token0);
        const token1 = await poolService.getToken1(currentNetworkConfig, poolAddress);
        const token1Metadata = await op20Service.GetTokenMetadata(currentNetworkConfig, token1);

        const data: UserPool = {
            userAddresa: user,
            balance,
            pool: {
                address: poolAddress,
                reserve0TokenAddress: token0,
                reserve0TokenSymbol: token0Metadata.symbol,
                reserve1TokenAdress: token1,
                reserve1TokenSymbol: token1Metadata.symbol,
            },
        };
        return data;
    }
}
